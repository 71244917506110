import React from "react";
import theme from "theme";
import { Theme, Text, Icon, Box, Link, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Les Lacs Adrénaline Paintball Arena
			</title>
			<meta name={"description"} content={"Libérez votre esprit tactique - Bienvenue au Paintball Les Lacs Adrénaline\n"} />
			<meta property={"og:title"} content={"Les Lacs Adrénaline Paintball Arena"} />
			<meta property={"og:description"} content={"Libérez votre esprit tactique - Bienvenue au Paintball Les Lacs Adrénaline\n"} />
			<meta property={"og:image"} content={"https://frenchwinselect.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://frenchwinselect.com/img/2513220.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://frenchwinselect.com/img/2513220.png"} />
			<meta name={"msapplication-TileImage"} content={"https://frenchwinselect.com/img/2513220.png"} />
		</Helmet>
		<Components.Header />
		<Section
			color="--color-darkL1"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			quarkly-title="Form-1"
		>
			<Text
				as="h1"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 20px 0"
				width="100%"
				text-align="center"
			>
				Contactez Les Lacs Adrénaline
			</Text>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Adresse
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Chem. des Sangliers, 83700 Saint-Raphaël, France
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Email
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:service@frenchwinselect.com" text-decoration="none" hover-text-decoration="underline" color="--color-darkL1">
									service@frenchwinselect.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Téléphone
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+33 4 945 240 33
							</Text>
						</Box>
					</Box>
				</Box>
				<Box width="50%" padding="8px 8px 8px 8px" md-width="100%">
					<Image src="https://frenchwinselect.com/img/4.jpg" display="block" width="100%" />
					<Box />
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});